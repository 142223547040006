.flag {
  margin-right: 2.5px;
}

.flag:last-child {
  margin-right: 0;
}

.flag:hover {
  cursor: pointer;
}
